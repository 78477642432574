<template>
  <div>
    <extended-page-header :title="$t('paths.logs')" />
    <router-view :key="$route.path"></router-view>
  </div>
</template>

<script>
export default {
  components: {
    "extended-page-header": () =>
        import("../components/logs/ExtendedPageHeader.vue"),
  },
};
</script>
